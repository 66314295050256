// Step 1: Import React
import * as React from 'react';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import { useTranslation} from 'gatsby-plugin-react-i18next';
import { Container, Row, Col } from "reactstrap";
import { BiLinkExternal } from "react-icons/bi";
import { StaticImage } from "gatsby-plugin-image";
import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'toastr/build/toastr.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; 


// Step 2: Define your component
const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <main>
      <Layout>
        <title>About Us</title>
        <Container className="siteStyl">
          <br />
          <Row>
            <blockquote><h3>{t('about1')}</h3></blockquote>         
            <p>{t('about2')}</p>
            <p>{t('about3')}</p>
            <p> - {t('about31')} </p>
            <p> - {t('about32')}</p>
            <p> - {t('about33')}</p>
            <p> - {t('about34')}</p>
          </Row>
          <br />
          <Row>
            <h3>{t('about5')}</h3>
          <Col md="2"> 
            <StaticImage src="../assets/images/Saptha.jpg" alt="Saptha" layout="fullWidth" />
            <h3>{t('about6')}</h3>
          </Col>
          <Col md="10">
            <p>{t('about7')} <a href="https://play.google.com/store/books/details?id=o6JREAAAQBAJ" target="_blank" rel="noopener noreferrer">"{t('about71')}" <BiLinkExternal /></a>, 
            &nbsp;{t('about72')} <a href="https://www.youtube.com/watch?v=VxWCwc08pBo" target="_blank" rel="noopener noreferrer">{t('about73')} <BiLinkExternal /></a>
            &nbsp;{t('about74')} </p>
            <p>{t('about8')}</p> 
            <p>{t('about9')} </p>
            <p>{t('about10')}</p>
          </Col>
          </Row>
        </Container>
      </Layout>
    </main>
    )
}

// Step 3: Export your component
export default AboutPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["about", "common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;